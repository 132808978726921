import { registerApplication, start } from "single-spa";
import { BehaviorSubject } from "rxjs";

const dsdsData = {
  token: "",
};
const authTokenSubject = new BehaviorSubject("");
const dsdsReportObservable = authTokenSubject.asObservable();

registerApplication({
  name: "@edgenuity/decision-support-data-system-mf",
  app: () => import("@edgenuity/decision-support-data-system-mf"),
  activeWhen: "/",
  customProps: {
    token: dsdsData.token,
    dsdsReportObservable,
  },
});

start();

// Display jwt manual input for testing
const isTest = new URLSearchParams(window.location.search).get("test");
document.getElementById("test-input").hidden = !isTest;

// Button to test BehaviorSubject
document.getElementById("buttonJwtSubject").onclick = () => {
  dsdsData.token = document.getElementById("inputJwt").value;
  authTokenSubject.next(dsdsData);
};

window.addEventListener(
  "message",
  (event) => {
    if (event.data?.token) {
      authTokenSubject.next(event.data);
    }
  },
  false
);
